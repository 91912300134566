import "./footer-section.scss";
import logo from "../../../images/logotipoBrathBranco.png";
import { Col, Row, Container } from "react-bootstrap";
import { Logotipo } from "../../../images/logotipo";

export const FooterSection: React.FC<any> = () => {
  const dataAtual = new Date();
  const anoAtual = dataAtual.getFullYear();

  return (
    <section id="Footer">
      <a
        href="https://api.whatsapp.com/send?phone=554430171158"
        title="WhatsApp"
        id="btn-whatsapp"
        target="_blank"
      >
        <i className="bi bi-whatsapp iconIcon"></i>
      </a>
      <Container className="copyrightco">
        <Row>
          <Col lg={3}>
            <img src={logo} width="100%" alt="logo-rodapé" />
            <div className="iconesRodape">
              <a
                href="https://www.instagram.com/brath.assessoria/"
                target="blank"
              >
                <i className="bi bi-instagram iconIcon"></i>
              </a>
              <a
                href="https://www.facebook.com/Brath-Assessoria-Cont%C3%A1bil-Empresarial-107686622031467"
                target="blank"
              >
                <i className="bi bi-facebook iconIcon"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/brath-assessoria-cont%C3%A1bil-empresarial-b3b953246/"
                target="blank"
              >
                <i className="bi bi-linkedin iconIcon"></i>
              </a>
            </div>
          </Col>
          <Col lg={3} className="itemRodape">
            <h3 className="tituloItem">Contato</h3>
            <div>
              <a href="tel:4430171158" className="links" title="(44) 3017-1158">
                (44) 3017-1158
              </a>
              <br />
              <a
                href="mailto:contato@brath.com.br"
                className="links"
                title="contato@brath.com.br"
              >
                contato@brath.com.br
              </a>
            </div>
          </Col>
          <Col lg={3} className="itemRodape">
            <h3 className="tituloItem">Endereço</h3>
            <div>
              <div className="links">
                Rua São Josafat, 1530 - Centro, Campo Mourão - PR, 87302-170
              </div>
            </div>
          </Col>
          <Col lg={3} className="itemRodape">
            <h3 className="tituloItem">Horário de atendimento</h3>
            <div>Segunda a Quinta-feira:</div>
            <div>Das 08h:00min às 11h:30min e das 13h:00min às 18h:00min</div>
            <div>Sexta feira:</div>
            <div>Das 08h:00min às 11h:30min e das 13h:00min às 17h:50min</div>
          </Col>
        </Row>

        <Container className="direitosAutorais">
          <Row className="rowCopyright">
            <Col lg={6} className="direitos">
              <p className="textoDireitos">
                © {anoAtual} | Brath Assessoria Contábil Empresarial - Todos os
                direitos reservados.
              </p>
            </Col>
            <Col lg={6} className="tytotech">
              <a
                className="containerTyto"
                href="https://tytotech.com.br/"
                title="Desenvolvido por Tytotech"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://tytotech.com.br/web-files/imagens/logos/logo-tytotech.svg"
                  alt="Tytotech"
                  width="85px"
                />
                <p className="versao">{process.env.REACT_APP_VERSION}</p>
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
};
