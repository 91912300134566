import React from "react";

import "./solutions-section.scss";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../../images/img-quem-somos.png";
import Card from "react-bootstrap/Card";

export const SolutionsSection: React.FC<any> = () => {
  return (
    <section id="secaoSolucoes">
      <Container>
        <Row>
          <Col lg={8} className="ColText">
            <h2>Soluções</h2>
            {/* <p>
              A facilidade na busca por informações, refletiu diretamente nos
              serviços contábeis atuais. O contador passou de um mero
              escriturador a um profissional de extrema importância, os serviços
              por esse prestado deve ser além de confiável, pontual e completo.
            </p> */}
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="ColCard">
            <Card className="Cards">
              <Card.Body>
                <img src={logo} alt="logo" />
                <Card.Title className="Titulo">Legalização</Card.Title>

                <Card.Text className="Texto">
                  Apoiamos sua empresa em todo o processo de abertura,
                  alteração, encerramento e gerenciamento de contratos, visando
                  a eficiência, segurança e controle do ciclo de vida
                  empresarial.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} className="ColCard">
            <Card className="Cards">
              <Card.Body>
                <img src={logo} alt="logo" />
                <Card.Title className="Titulo">Tributário</Card.Title>

                <Card.Text className="Texto">
                  Analisamos e apresentamos a melhor forma de tributação para o
                  seu negócio por meio de um Planejamento Tributário. Na área
                  fiscal, oferecemos mensalmente os cálculos de impostos e
                  acompanhamento dos mesmos, emitindo as guias, de forma
                  digital, para você.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} className="ColCard">
            <Card className="Cards">
              <Card.Body>
                <img src={logo} alt="logo" />
                <Card.Title className="Titulo">Departamento Pessoal</Card.Title>

                <Card.Text className="Texto">
                  Com uma equipe altamente especializada, oferecemos os melhores
                  serviços de departamento pessoal para sua empresa, agilizando
                  e dando segurança às rotinas de folha de pagamento.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} className="ColCard">
            <Card className="Cards">
              <Card.Body>
                <img src={logo} alt="logo" />
                <Card.Title className="Titulo">Contabilidade</Card.Title>

                <Card.Text className="Texto">
                  Por meio das demonstrações contábeis, facilitamos a análise e
                  identificação da saúde da sua empresa. Acompanhe lucros,
                  prejuízos, receitas, custos e despesas, durante todo o ano
                  calendário.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
