import React from "react";
import "./thumbnail-section.scss";
import Carousel from "react-bootstrap/Carousel";
import bannerImg from "../../../images/banner.png";
import bannerImg2 from "../../../images/banner-2.png";
import bannerImg3 from "../../../images/banner-3.png";
import bannerImg4 from "../../../images/banner-4.png";
import bannerImg5 from "../../../images/banner-5.png";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export const ThumbnailSection: React.FC<any> = () => {
  return (
    <Container fluid id="sessaoThumbnail">
      <div className="redesSociais">
        <div className="d-grid">
          <a href="https://www.instagram.com/brath.assessoria/" target="blank">
            <i className="bi bi-instagram iconIcon"></i>
          </a>
          <a href="https://www.facebook.com/Brath-Assessoria-Cont%C3%A1bil-Empresarial-107686622031467" target="blank">
            <i className="bi bi-facebook iconIcon"></i>
          </a>
          <a href="https://www.linkedin.com/in/brath-assessoria-cont%C3%A1bil-empresarial-b3b953246/" target="blank">
            <i className="bi bi-linkedin iconIcon"></i>
          </a>
        </div>
      </div>

      <Carousel fade>
        {/* <Carousel.Item>
          <img className="d-block w-100" src={bannerImg} alt="First slide" />
          <Carousel.Caption>
            <h3>LEGALIZAÇÃO</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit
              felis in est gravida risus, suspendisse sollicitudin.
            </p>
            <Button variant="primary">Saiba mais</Button>
          </Carousel.Caption>
        </Carousel.Item> */}
        <Carousel.Item>
          <img className="d-block w-100" src={bannerImg} alt="First slide" />

          <Carousel.Caption>
            <h3>Legalização</h3>
            <p className="descricao">
              Apoiamos sua empresa em todo o processo de abertura, alteração,
              encerramento e gerenciamento de contratos, visando a eficiência,
              segurança e controle do ciclo de vida empresarial.
            </p>
            {/* <Button variant="primary">Saiba mais</Button> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={bannerImg3} alt="Third slide" />

          <Carousel.Caption>
            <h3>Tributário</h3>
            <p className="descricao">
              Analisamos e apresentamos a melhor forma de tributação para o seu
              negócio por meio de um Planejamento Tributário.
            </p>
            {/* <Button variant="primary">Saiba mais</Button> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={bannerImg4} alt="Third slide" />

          <Carousel.Caption>
            <h3>Departamento Pessoal</h3>
            <p className="descricao">
              Com uma equipe altamente especializada, oferecemos os melhores
              serviços de departamento pessoal para sua empresa, agilizando e
              dando segurança às rotinas de folha de pagamento.
            </p>
            {/* <Button variant="primary">Saiba mais</Button> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={bannerImg5} alt="Third slide" />

          <Carousel.Caption>
            <h3>Contabilidade</h3>
            <p className="descricao">
              Por meio das demonstrações contábeis, facilitamos a análise e
              identificação da saúde da sua empresa. Acompanhe lucros,
              prejuízos, receitas, custos e despesas, durante todo o ano
              calendário.
            </p>
            {/* <Button variant="primary">Saiba mais</Button> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Container>
  );
};

export default ThumbnailSection;
