import React from "react";
import { Container } from "react-bootstrap";

import "./maps-section.scss";

export const MapsSection: React.FC<any> = () => {
  return (
    <Container fluid id="sessaoMaps">
      <iframe
        title="maps"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14574.432883673408!2d-52.384522!3d-24.044877!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb0629b6879bc1196!2sESCRITORIO%20BRATH%20DE%20CONTABILIDADE!5e0!3m2!1spt-BR!2sbr!4v1660073600557!5m2!1spt-BR!2sbr"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Container>
  );
};
