import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./instagram-section.scss";
import Carousel from "react-bootstrap/Carousel";
import insta from "../../../images/instagram.png";
import img1 from "../../../images/imgcarrosel-01.png";
import img2 from "../../../images/imgcarrosel-02.png";
import img3 from "../../../images/imgcarrosel-03.png";
import { InstagramPost } from "../../../models/instagram-post";
import { gestorReqs } from "../../../services/gestor";

export const InstagramSection: React.FC<any> = () => {
  const [instagramPosts, setInstagramPosts] = React.useState<InstagramPost[]>();
  const [splitedPosts, setSplitedPosts] = React.useState<InstagramPost[][]>();

  React.useEffect(() => {
    gestorReqs.getPostsInstagram().then((response) => {
      setInstagramPosts(response?.filter((ip) => ip.media_type === "IMAGE"));

      if (response) {
        var responseFiltered = response
          ?.filter((ip) => ip.media_type === "IMAGE")
          .slice(0, 15);
        var splitedPostAux: InstagramPost[][] = [];

        var mainIdx = 0;
        var idx = 0;

        while (1) {
          var aux: InstagramPost[] = [];
          for (let i of [0, 1, 2]) {
            aux.push(responseFiltered[idx]);
            idx += 1;
            if (idx >= responseFiltered.length) break;
          }

          splitedPostAux.push(aux);

          if (idx >= responseFiltered.length) break;
          mainIdx += 1;
        }

        setSplitedPosts(splitedPostAux);
      }
    });
  }, []);

  return (
    <section id="sessaoInsta">
      <Container>
        <Row>
          <Col lg={12} className="ColText">
            <a
              href="https://www.instagram.com/brath.assessoria/"
              target="blanck"
              title="@brath.assessoria"
              className="linkInstagram"
            >
              <img src={insta} alt="logo" />
              <h2>@brath.assessoria</h2>
            </a>
          </Col>
        </Row>

        <Row>
          <Col lg={12} className="ColCarrosel">
            {splitedPosts && splitedPosts.length && (
              <Carousel controls={splitedPosts.length > 1} indicators={false}>
                {splitedPosts ? (
                  splitedPosts.map((sp, i) => (
                    <Carousel.Item key={i}>
                      {sp.map((ip, ci) => (
                        <a href={ip.permalink} target="_blank" key={ci}>
                          <img className=" " src={ip.media_url} alt="logo" />
                        </a>
                      ))}
                    </Carousel.Item>
                  ))
                ) : (
                  <></>
                )}
              </Carousel>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="ColCarroselMobile">
            {instagramPosts && instagramPosts.length && (
              <Carousel controls={false}>
                {instagramPosts?.slice(0, 5).map((ip, i) => (
                  <Carousel.Item key={i}>
                    <a href={ip.permalink} target="_blank">
                      <img className="" src={ip.media_url} alt="logo" />
                    </a>
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
