import React, { useEffect, useState } from "react";
import "./App.scss";
import Button from 'react-bootstrap/Button';
import { MenuSection } from "./component/sections/menu-section";
import { ThumbnailSection } from "./component/sections/thumbnail-section";
import { QuemSomos } from "./component/sections/quem-somos-section";
import { SolutionsSection } from "./component/sections/solutions-section";
import { InstagramSection } from "./component/sections/instagram-section";
import { ContactSection } from "./component/sections/contact-section";
import { MapsSection } from "./component/sections/maps-section";
import { FooterSection } from "./component/sections/footer-section";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom"
import { gestorReqs } from "./services/gestor";
import ReactGA from 'react-ga4'

function App() {
  return (
    <>
      <React.StrictMode>
        <Router>
          <h1 className="d-none">Brath Assessoria Contábil Empresarial</h1>
          <TrackingRoute />
          <MenuSection></MenuSection>
          <ThumbnailSection></ThumbnailSection>
          <QuemSomos></QuemSomos>
          <SolutionsSection></SolutionsSection>
          <InstagramSection></InstagramSection>
          <ContactSection></ContactSection>
          <MapsSection></MapsSection>
          <FooterSection></FooterSection>
        </Router>
      </React.StrictMode>
    </>
  );
}

const TrackingRoute = () => {
  const location = useLocation()
  const [trackingId, setTrackingId] = useState<string | undefined>();

  useEffect( () => {
      gestorReqs.getInformacoes().then(response => {
          if(!response.ga_tracking_id)    return

          const ok = configAnalytics(response.ga_tracking_id)
          if(ok)  setTrackingId(response.ga_tracking_id)
      })
  }, [])

  useEffect( () => {
      if(trackingId){
          ReactGA.send({
              hitType: 'pageview',
              page: window.location + window.location.search,
          });
      }
  }, [location])

  return (<></>)
}

function configAnalytics(tracking_id: string): Boolean {
  try {
    ReactGA.initialize(tracking_id, {
      gaOptions: { cookieFlags: 'SameSite=None; Secure' },
    });
    return true;
  }catch(e: any){
    console.info("Erro when configure analytics: ", e)
    return false;
  }
}

export default App;
