export type SendEmailForm = {
  email: string
  telefone: string
  nome: string
  assunto: string
  mensagem: string
  captcha: string
}

export enum PostHttpStatus {
  OK,
  ERROR
}