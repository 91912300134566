import React from "react";
// import logo from "../../../images/img-quem-somos.png";
import logo from "../../../images/Selo20Anos - Brath.jpg";
import "./quem-somos-section.scss";
import { Container, Row, Col } from "react-bootstrap";

export const QuemSomos: React.FC<any> = () => {
  return (
    <section id="secaoSobre">
      <Container>
        <Row>
          <Col lg={2}></Col>
          <Col lg={8} className="ColImg">
            <div className="imagem">
              <img src={logo} alt="logo" />
            </div>
          </Col>
          <Col lg={2}></Col>

          <Col lg={2}></Col>
          <Col lg={8} className="ColText">
            <div className="textos">
              <h2>Quem somos</h2>

              <p>
                Fundada em 2002, a Brath Assessoria Contábil Empresarial
                completou 20 anos de atuação na área de serviços contábeis e de
                consultoria empresarial. São duas décadas simplificando a gestão
                empresarial.
              </p>

              <p>
                Na Brath, nosso objetivo é oferecer um atendimento especializado
                com contato próximo ao cliente, garantindo que as informações
                sejam transformadas em relatórios fidedignos e relevantes para
                seus usuários.
              </p>
              <p>
                Oferecemos as melhores soluções nas áreas contábil, fiscal,
                tributária, rural, financeira e departamento pessoal, além de
                assessoria empresarial para uma melhor gestão administrativa e
                de custos de sua empresa.
              </p>
              <p>
                Nossa equipe é composta por contadores, administradores e
                técnicos de alta competência, que são responsáveis pela
                qualidade dos serviços contábeis prestados para pequenas, médias
                e grandes empresas que atuam no mercado nacional.
              </p>
              <p>
                Dispomos de uma estrutura operacional moderna, equipada e
                informatizada. Nossa rotina em nuvem nos possibilita o
                oferecimento de soluções 100% online, capazes de atender
                clientes de todo o Brasil. Assim, as empresas podem contar com
                ferramentas tecnológicas para a rápida e qualitativa informação
                contábil.
              </p>
              <p>
                Nossas decisões e ações são pautadas pela ética e pela moral,
                valorizamos relacionamentos de longo prazo, garantindo sempre
                segurança, qualidade, confiabilidade e responsabilidade dos
                serviços prestados.
              </p>
            </div>
          </Col>
          <Col lg={2}></Col>
        </Row>
      </Container>
    </section>
  );
};
