import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../../images/logotipoBrath.png";
import "./menu-section.scss";

export const MenuSection: React.FC<any> = () => {
  const [scroll, setScroll] = useState("");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50 ? "scrollOn" : "scrollOff");
    });
  }, []);

  return (
    <Navbar fixed="top" expand="lg" className={scroll}>
      <Container id="sessaoMenu">
        <Navbar.Brand href="#home">
          <img className="logotipo" src={logo} alt="logotipo-Brath" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className="botaoMenu" href="/" title="HOME">
              HOME
            </Nav.Link>
            <Nav.Link className="botaoMenu" href="#secaoSobre" title="QUEM SOMOS">
              QUEM SOMOS
            </Nav.Link>
            <Nav.Link className="botaoMenu" href="#secaoSolucoes" title="SOLUÇÕES">
              SOLUÇÕES
            </Nav.Link>
            <Nav.Link className="botaoMenu" href="#secaoContato" title="CONTATO">
              CONTATO
            </Nav.Link>
            <Button variant="primary">
              <a href="https://onvio.com.br/clientcenter/pt/auth?r=%2Fhome" target="blanck" className="btnCliente" title="ÁREA DO CLIENTE">
                ÁREA DO CLIENTE
              </a>
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
