export const mascararTelefone = (tel: string) => {
  var telMasked = tel.replace(/[^\d]/g, "");
  if (telMasked.length <= 9)
    telMasked = telMasked.replace(/(\d{2})(\d)/, "($1) $2");
  else
    telMasked = telMasked.replace(
      /(\d{2})(\d{5}|\d{4})(\d{4})/,
      "($1) $2-$3"
    );

  if (telMasked.length > 15) telMasked = telMasked.substring(0, 15);
  return telMasked;
};