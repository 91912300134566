import React, { useEffect } from "react";
import "./contact-section.scss";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert';
import { PostHttpStatus, SendEmailForm } from "../../../models/send-email";
import { mascararTelefone } from "../../../tools/regexs";
import { gestorReqs } from "../../../services/gestor";
import ReCAPTCHA from "react-google-recaptcha";

type PostStatus = 'OK' | 'ENVIANDO' | 'ERROR'

export const ContactSection: React.FC<any> = () => {
  const [nome, setNome] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [telefone, setTelefone] = React.useState<string>('');
  const [assunto, setAssunto] = React.useState<string>();
  const [descricao, setDescricao] = React.useState<string>();

  const [inputStates, setInputStates] = React.useState<any>({});
  const [postStatus, setPostStatus] = React.useState<PostStatus>();

  const [captcha, setCaptcha] = React.useState<any>(undefined);
  const [showCaptchaAlert, setCaptchaAlert] = React.useState<boolean>(false);
  const [captchaSiteKey, setCaptchaSiteKey] = React.useState<string>();

  const limparInputs = () => {
    var inputs = document.getElementsByTagName("input");
    for (var i = 0; i < inputs.length; i++) {
        if(inputs[i].className.indexOf("limparInput") != -1){
          inputs[i].value = ""
        }
    }

    var campos = document.getElementsByTagName("textarea");
    for (var i = 0; i < campos.length; i++) {
        if(campos[i].className.indexOf("limparInput") != -1){
          campos[i].value = ""
        }
    }

    setTelefone("")

    setInputStates({
      nome: undefined,
      email: undefined,
      telefone: undefined,
      descricao: undefined,
      assunto: undefined
    })

    setCaptcha(undefined)
  }

  useEffect(() => {
    gestorReqs.getInformacoes().then(res => {
      setCaptchaSiteKey(res.captcha_site_key)
    })
  }, [])

  useEffect(() => {
    if (captcha) setCaptchaAlert(false);
  }, [captcha]);

  const handlerNameOnBlur = (value: string) => {
    if(value.length >= 3){
      setNome(value)
      setInputStates({...inputStates, nome: true})
    }else{
      setNome(undefined)
      setInputStates({...inputStates, nome: false})
    }
  }

  const handlerEmailOnBlur = (value: string) => {
    var regex = new RegExp("^[a-z0-9.]+@[a-z0-9]+.[a-z]+.([a-z]+)?$");
    if(regex.test(value)){
      setNome(value)
      setInputStates({...inputStates, email: true})
    }else{
      setNome(undefined)
      setInputStates({...inputStates, email: false})
    }
  }

  const handlerTelefoneOnBlur = (value: string) => {
    if(value.length >= 3){
      setTelefone(value)
      setInputStates({...inputStates, telefone: true})
    }else{
      setTelefone('')
      setInputStates({...inputStates, telefone: false})
    }
  }

  const handlerAssuntoOnBlur = (value: string) => {
    if(value.length >= 3){
      setAssunto(value)
      setInputStates({...inputStates, assunto: true})
    }else{
      setAssunto(undefined)
      setInputStates({...inputStates, assunto: false})
    }
  }

  const handlerDescricaoOnBlur = (value: string) => {
    if(value.length > 0){
      setDescricao(value)
      setInputStates({...inputStates, descricao: true})
    }else{
      setDescricao(undefined)
      setInputStates({...inputStates, descricao: false})
    }
  }

  const submitHandler = () => {
    setPostStatus(undefined);
    setInputStates({
      nome: inputStates.nome ? inputStates.nome : false,
      email: inputStates.email ? inputStates.email : false,
      telefone: inputStates.telefone ? inputStates.telefone : false,
      descricao: inputStates.descricao ? inputStates.descricao : false,
      assunto: inputStates.assunto ? inputStates.assunto : false
    })

    if(inputStates.nome && inputStates.email && inputStates.telefone && inputStates.descricao && inputStates.assunto){
      if(captcha==undefined){
        setCaptchaAlert(true);
      }else{
        var dados = {
          email,
          telefone,
          nome,
          assunto,
          mensagem: descricao,
          captcha: captcha
        } as SendEmailForm;

        setPostStatus('ENVIANDO');
        gestorReqs.postEmail(dados).then(res => {
          if (res === PostHttpStatus.OK) {
            setPostStatus('OK')
            limparInputs();
          } else {
            setPostStatus('ERROR')
          }
        });
      }
    }
  }

  const handlerTelefoneChange = (value: string) => {
    setTelefone(mascararTelefone(value));
  }

  return (
    <section id="secaoContato">
      <Container>
        <Row>
          <Col lg={12} className="ColText">
            <h2>Fale Conosco</h2>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="ColInput">
            <Form.Control className="limparInput" type="text" placeholder="Seu nome" isValid={inputStates.nome} isInvalid={!inputStates.nome  && inputStates.nome != undefined} onBlur={(e) => handlerNameOnBlur(e.target.value)}/>
            <Form.Control.Feedback type="invalid">Nome Inválido</Form.Control.Feedback>
          </Col>
          <Col lg={6} className="ColInput">
            <Form.Control className="limparInput" type="text" placeholder="Seu e-mail" isValid={inputStates.email} isInvalid={!inputStates.email  && inputStates.email != undefined} onBlur={(e) => handlerEmailOnBlur(e.target.value)}/>
            <Form.Control.Feedback type="invalid">Email Inválido</Form.Control.Feedback>
          </Col>
          <Col lg={6} className="ColInput">
            <Form.Control className="limparInput" type="text" placeholder="Seu telefone" value={telefone} isValid={inputStates.telefone} isInvalid={!inputStates.telefone  && inputStates.telefone != undefined} onBlur={(e) => handlerTelefoneOnBlur(e.target.value)} onChange={(e) => handlerTelefoneChange(e.target.value)}/>
            <Form.Control.Feedback type="invalid">Telefone Inválido</Form.Control.Feedback>
          </Col>
          <Col lg={6} className="ColInput">
            <Form.Control className="limparInput" type="text" placeholder="Assunto" isValid={inputStates.assunto} isInvalid={!inputStates.assunto  && inputStates.assunto != undefined} onBlur={(e) => handlerAssuntoOnBlur(e.target.value)}/>
            <Form.Control.Feedback type="invalid">Assunto Inválido</Form.Control.Feedback>
          </Col>
          <Col lg={12} className="ColInputDescription">
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control as="textarea" rows={5} placeholder="Descrição" className="limparInput" isValid={inputStates.descricao} isInvalid={!inputStates.descricao  && inputStates.descricao != undefined} onBlur={(e) => handlerDescricaoOnBlur(e.target.value)}/>
              <Form.Control.Feedback type="invalid">Por favor, digite algo!</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6} className="ColRecaptcha">
            {captchaSiteKey && (
              <ReCAPTCHA
                sitekey={captchaSiteKey}
                onChange={(value) => setCaptcha(value)}
                onErrored={() => setCaptcha(undefined)}
                onExpired={() => setCaptcha(undefined)}
              />
            )}
          </Col>
          <Col xs={12} md={6} className="ColButton">
            <Button variant="primary" size="lg" onClick={submitHandler}>
              Enviar mensagem
            </Button>{" "}
          </Col>
          { postStatus && 
            <Col lg={12} className='alertBox'>
              <Alert variant={postStatus === 'ENVIANDO' ? 'primary' : postStatus === 'OK' ? 'success' : 'danger'}>
                {postStatus === 'ENVIANDO' ? 'Enviando email ...' : postStatus === 'OK' ? 'Email enviado com sucesso!' : 'Erro ao enviar email! Tente novamente mais tarde!'}
              </Alert>
            </Col>
          }
          { showCaptchaAlert && 
            <Col lg={12} className='alertBox'>
              <Alert variant='secondary'>
                Por favor, responda o ReCAPTCHA !
              </Alert>
            </Col>
          }
        </Row>
      </Container>
    </section>
  );
};
