import axios from "axios";
import Informacoes from "../models/informacoes";
import { InstagramPost } from "../models/instagram-post";
import { PostHttpStatus, SendEmailForm } from "../models/send-email";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
      company: process.env.REACT_APP_APP_COMPANY!,
      Accept: 'application/json',
  }
});

const postEmail = async (body: SendEmailForm): Promise<PostHttpStatus> => {
  try {
      const { data, status } = await api.post<SendEmailForm>(`/site/contato`, body);

      return PostHttpStatus.OK;
  } catch (error) {
      if (axios.isAxiosError(error)) {
          console.warn('error on postEmail - message: ', error.message);
          return PostHttpStatus.ERROR;
      } else {
          console.warn('unexpected error on postEmail: ', error);
          return PostHttpStatus.ERROR;
      }
  }
}

const getInformacoes = async (): Promise<Informacoes> => {

  const retorno = new Informacoes;

  try {

      const { data } = await api.get("/site/informacoes") as any;

      return retorno.deserialize(data);

  } catch (error) {

      if (axios.isAxiosError(error)) {
          console.warn("error on informacoes - message: ", error.message);
      } else {
          console.warn("unexpected error on informacoes: ", error);
      }

      return retorno;
  }
}

const getPostsInstagram = async (): Promise<InstagramPost[] | undefined> => {
    try {
        const { data } = await api.get('/instagram/posts')

        return data as InstagramPost[]
        
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.warn("error on informacoes - message: ", error.message);
        } else {
            console.warn("unexpected error on informacoes: ", error);
        }
  
        return undefined;
    }
}


export const gestorReqs = {
  postEmail,
  getInformacoes,
  getPostsInstagram
}